<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h1>{{ $t('Add FAQ Section Item') }}</h1>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.faq_sections.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Section Group Name"
                                :error-messages="errors.section_group_name"
                                v-model="faq_section.section_group_name">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Section Title"
                                :error-messages="errors.section_title"
                                v-model="faq_section.section_title">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <text-editor
                                label="Section Content"
                                :errors="errors.section_content"
                                v-model="faq_section.section_content"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Section Order Group"
                                :error-messages="errors.order_group"
                                v-model="faq_section.order_group">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Section Order"
                                :error-messages="errors.order"
                                v-model="faq_section.order">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="orange"
                                large
                                value="Save"
                                @click="handleSave"
                            >
                                <span>{{ $t('Save') }}</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import FaqSections from "@/models/admin/FaqSections";
import TextEditor from "@/components/app/form/TextEditor";

export default {
    name: "faq-sections-add",
    components: {
        TextEditor,
        AdminDashboardLayout,
    },
    data: function () {
        return {
            faq_section: {},
            errors: {},
        }
    },
    methods: {
        async handleSave() {
            await FaqSections.$http.post('/api/admin/faq-sections', this.faq_section).then(() => {
                this.$store.commit('showSnackBar', this.$t('messages.success'))
                this.$router.push('/admin/faq-sections')
            }).catch((e) => {
                this.errors = e.response.data.errors
                this.$store.commit('showSnackBar', e.response.data.message)
            })
        },
    },
    async mounted() {
    },
}
</script>

<style lang="scss">
</style>
